@use '../../styles/colors.scss';
@use '../../styles/fonts.scss';

.invoiceNumber{
  color: $wa-heading-font-color;
  font-family: $body-font-family;
  font-size: 25px;
  font-weight: bold;
}

.invoiceTotalLabel, .invoiceTotalValue {
  font-family: $wa-m-plus-rounded-font-family;
  font-size: 20px;
  font-weight: bold;
}

.invoiceTotalLabel{
  color: $control-text;
}

.invoiceTotalValue{
  color: $wa-heading-font-color;
}

.nopadding {
  padding: 0 !important;
 // margin: 0 !important;
}

.topPadding {
  margin-top: 40px;
}
.marginBottom{
    margin-bottom:10px !important;
    padding:0px !important;
}
.invoice-total-right {
  float: right; 
}

.invoice-table-container {
    background: #DFECF5;
    height: 40px;
    border-collapse: collapse;
}

.invoice-detail-back-button {
    /* Control */
    background: $wa-back-button-color;
    border: 1px solid #AEB4B7;
    box-sizing: border-box;
    border-radius: 5px;
}

.invoice-detail-back-button-text {
    /* Text */
    height: 25px;
    font-family: $wa-m-plus-rounded-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #42424A;
}

.invoice-detail-pay-button {
    background: $primary-accent;
    border: 1px solid #AEB4B7;
    box-sizing: border-box;
    border-radius: 5px;
}

.invoice-detail-pay-button-text {
    height: 25px;
    font-family: $wa-m-plus-rounded-font-family;
    font-style: normal;
    /*font-weight: bold;
    font-size: 17px;*/
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #FFFFFF;
}

.invoice-detail-header-text {
    font-family: $wa-m-plus-rounded-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */
    letter-spacing: 0.564706px;
    color: $control-text;
}

.invoice-detail-column-header {
    font-family: $wa-m-plus-rounded-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 0.96rem;
    line-height: 25px;
    letter-spacing: 0.4px;
    color: $control-text;
    background: $register-background;
    padding: 0.75rem 0;
}

.invoice-detail-grid-cell {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.329412px;
    color: #4A4A4A;
}

.invoice-detail-invoice-total-label {
    font-family: $wa-m-plus-rounded-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.4px;
    color: $control-text;
    margin-right:10px;
    padding-right:2em;
}

.invoice-detail-invoice-total-text {
    font-family: $wa-m-plus-rounded-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    /* identical to box height */
    letter-spacing: 0.42353px;
    color: #00BD5D;
    padding-right: 55px;
    margin: 0em 1em;
    float:right;
}

@media (min-width: 1px) and (max-width: 500px) {
    .invoice-detail-back-button, .invoice-detail-pay-button {
        padding: 0.5em 0.5em !important;
        font-size: 10px;
    }

    .btn-lg, .btn-group-lg > .btn {
        padding: 0.5em 0.5em !important;
    }
    .pl0{
        padding-left:0px !important;
    }   
    .invoice-detail-back-button-text, .invoice-detail-pay-button-text{
        font-size:14px !important;
        font-weight:bold !important;
    }
}
@media (min-width: 767px) and (max-width:1023px) {
    .container {
        flex-direction: column;
        align-items: flex-end;
    }

    .invoice-detail-invoice-total-label,
    .invoice-detail-invoice-total-text {
        padding: 0px;
        font-size: 16px;
        font-weight: 400;
    }
   
}
@media (min-width: 1024px) and (max-width:1249px) {

    .invoice-detail-invoice-total-label{
        padding:0px;
        font-weight:400;
    }
    .invoice-detail-invoice-total-text {
        padding: 0px;
        font-weight: 400;
        margin: 0em 3em;
    }
}
@media (min-width: 1250px) and (max-width:1439px) {

    .invoice-detail-invoice-total-label {
        padding: 0px;
        font-weight: 400;
    }

    .invoice-detail-invoice-total-text {
        padding: 0px 2px;
        font-weight: 400;
        margin: 0em 4em 0em 1em;
    }
}
@media (min-width: 1440px) and (max-width:1535px) {

    .invoice-detail-invoice-total-label {
        padding: 0px;
        font-weight: 400;
    }

    .invoice-detail-invoice-total-text {
        padding: 0px 4px;
        font-weight: 400;
        margin: 0em 5em 0em 1em;
    }
}