$wa-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

.print-details-text {
    display: none; /* Initially hide the text in print window */
}

.d-print-none {
    display: block !important;
}

.d-print-text-none {
    display: none !important;
}


@media print {

    .d-print-none {
        display: none !important;
    }

    .d-print-block {
        display: block !important;
    }

    .print-details {
        margin-top: 1em; /* Adjust the margin as needed */
    }

    .print-details-text {
        display: block !important; /* Show the text in the print window */
    }

    .d-print-text-none {
        display: block !important;
    }
}
