@use '../../styles/colors.scss';
@use '../../styles/fonts.scss';
@use '../../styles/common.scss';

.webar-navbar {
    background-color: $wa-theme-color !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    z-index: 1100;
    white-space: nowrap;
    border-bottom: 1px solid rgba(116, 116, 116, 0.75);
}

.webar-navbar-Maintenance {
    background-color: $wa-theme-color !important;
    padding-top: 60px !important;
    padding-bottom: 30px !important;
    z-index: 1100;
    white-space: nowrap;
    border-bottom: 1px solid rgba(116, 116, 116, 0.75);
}

.webar-navbar .navbar-nav, .navbar-nav {
    a, .nav-link {
        color: $wa-nav-link-color;
        font-family: $wa-m-plus-rounded-font-family;
        font-weight: 500;
    }

    a:hover {
        color: #03838c
    }

    .nav-link.active {
        border-bottom: $wa-nav-link-active-color;
        border-radius: 0px;
        color: $wa-nav-link-color;
    }

    a.dropdown-item {
        color: $wa-control-text;
    }
}

/* Not used */
.webar-navbar-logo {
    width: auto;
    height: 45px;
}

/* Not used */
@media(min-width: 992px) {
    .webar-navbar-logo {
        width: auto;    
        height: 60px;
    }
}

.webar-navbar-collapse {
    align-self: flex-end;
}

.nav-item {
    padding-left: 10px;
    padding-right: 10px;
}

.nav-item .customSelect .dropbtn {
    color: $wa-nav-link-color !important;
    background-image: linear-gradient(to top, #03838c 8%, #016067);
}

.userPadding {
    padding-top: 7px;
}

/* Dropdown Button */
.dropbtn {
    background-color: $wa-theme-color;
    color: $wa-nav-link-color;
    padding-right: 10px;
    padding-top: 8px;
    font-size: 16px;
    border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown:hover {
    /* Show the dropdown menu on hover */
    .dropdown-content {
        display: block;
    }
    /* Change the background color of the dropdown button when the dropdown content is shown */
    .dropbtn {
        background-color: $wa-theme-color
    }
}

/* Dropdown Content (Hidden by Default) */
/* Not used */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    -webkit-border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    z-index: 1;
    /* Links inside the dropdown */
    a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }
    /* Change color of dropdown links on hover */
    a:hover {
        background-color: #ddd
    }
}

.dropdownPadding {
    padding-top: 4px;
}


.dropbtn .select-arrow {
    margin-left: 0;
    padding-left: 0.5rem;
    color: $wa-nav-link-color;
}

.headerDropdownItemColor {
    background-color: $wa-table-header-color;
}

#appHeader {
    .truncate {
        //width: 225px;
    }

    .dropdown-toggle::after {
        color: $wa-nav-link-color;
        display: none;
    }

    .account-subheader {
        font-size: 12px;
        text-align: left;
    }

    .Maintenanceheader-forecolor {
        color: goldenrod !important;
    }
}

.header-dropdown-overflow {
    overflow-y: scroll;
    max-height: 560px;
}

.epay-portal-title {
    float: right;
    color: #005191;
    font-weight: 400;
    font-size: 21px;
    vertical-align: middle;
    font-family: Helvetica;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
}

.header-user-detail {
    color: white;
}

.mouseHover {
    span:hover {
        color: #03838c;
    }
}
.svg-inline--fa.fa-cog.fa-w-16.fa-2x:hover {
    color: #000000;
}