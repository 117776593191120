@use '../../styles/colors.scss';
@use '../../styles/fonts.scss';

.user-name {
    color: #4A4A4A;
    font-family: $body-font-family;
    font-size: 20px;
    font-weight: bold;
}

  .reset-password {
    text-decoration: underline;
    color: $wa-heading-font-color;
    font-weight: bold;
  }

  .truncate {
    width: auto;
  }
  .buttonsize{
    width: 157px !important;
  }