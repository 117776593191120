
.buttonColor {
    background-color: $wa-button-color;
}

.buttonsize {
    width: 157px !important;
}

.customDatePickerWidth, .customDatePickerWidth input,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
    width: 90%;
    /*margin-right:25px;*/
    margin: .4rem 0;
}

.datetimepickerLabel {
    /* margin-right: 15px; */
    margin-top: 2px;
    margin-bottom: 0;
}

.datetimepickerdiv {
    display: inline-flex;
}

.datePicker {
    border-width: 1px;
    border-style: solid;
    border-color: #cccccc;
}

.TextMultiline {
    height: calc(4.5em + 0.75rem + 2px);
    word-break: break-all;
}
.form-check-inline-date, .form-check-inline-date-first {
    display: inline-block;
    align-items: center;
    padding-left: 0;
    
}
.form-check-inline-date-first {
    margin-right: 1.75rem;
}
.form-check-inline-date {
    margin-right: 0.75rem;
}



  input[type="datetime-local"] {
    position: relative;
    padding: 10px;
  }
  
  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }
  
  input[type="datetime-local"]:before {
     
    background: none;
    display: block;
    font-family: 'FontAwesome';
    content: '\f078';
    /* This is the calendar icon in FontAwesome */
    width: 15px;
    height: 20px;
    position: absolute;
    top: px;
    right: 6px;
    color:#1A75BB;
  }
 