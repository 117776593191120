@use '../../styles/colors.scss';
@use '../../styles/fonts.scss';

// *NOTE*
//This isn't used
.depositsHeading{
  color: $wa-heading-font-color;
  font-family: $body-font-family;
  font-size: 25px;
  font-weight: bold;
  margin-top: 10px;
}

//This could probably be replaced with pb-1 class (0.25rem padding = 4px) or pb-2 (8px)
.bottompadding {
  padding-bottom: 5px;
}

.is-disabled {
    pointer-events: none;
    opacity: 0.7;
}

/* For Firefox */
.depositAmount input[type='number'] {
  -moz-appearance:textfield;
}
/* Webkit browsers like Safari and Chrome */
.depositAmount input[type=number]::-webkit-inner-spin-button,
.depositAmount input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}