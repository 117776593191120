@use "../../styles/colors.scss";
@use "../../styles/common.scss";
@use "../../styles/fonts.scss";

.manage-payment-button{
  color:#336699;
  text-decoration: underline;
  font-weight:bold;
  cursor:pointer;
  user-select:none;
}

.manage-payment-section{
  background-color: $wa-page-background;
  border: 1px solid $wa-theme-color;
  border-radius:3px;
  padding: 0.6em;
}

.overpaying-text{
  padding-left:0.2em;
  line-height:1em;
  font-size:0.8em;
}

.payment-totals{
//  font-size:1.2em; 
}

.paymentCustomSelect select, .paymentCustomSelect button {
  color: $wa-theme-color !important;
  display: inline-block;
  font-family: $wa-m-plus-rounded-font-family;
  font-size: 14px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.buttonColor {
    color: #fff;
    background-color: $wa-button-color;
}
.buttonsize{
  width: 157px !important;
}
.btnPay:disabled {
    color: #fff;
    background-color: forestgreen0;
    border-color: #03838c;
}
.btn:hover {
    color: #fff;
    background-color: #016067;
}

