@use '../../styles/fonts.scss';
@use '../../styles/colors.scss';


#appFooter{
    background-color: white;
    font-size: 12px !important;
}

#appFooter.navbar {
    min-height:40px !important;
}

#appFooter a {
  color: #6c757d;
  text-decoration: none;  
}

@media (min-width: 768px) {
    #appFooter.navbar {
        min-height:60px !important;
    }
}

@media (min-width: 1px) and (max-width: 991px) {
    .navbar-nav {
        flex-direction: row;
        display: contents !important;
    }

    .navbar-nav li {
        display: table-cell;
    }

    .react-bootstrap-table table {
        table-layout: auto !important;
    }

    .hideonsmall {
        display: none !important;
    }
}
    @media (min-width: 992px) {
        .hideonsmall {
            display: block;
        }
    }