// @use "sass:map" as map;
// @use "../../styles/colors.scss" as colors;
// @use "../../styles/common.scss";
// @use "../../styles/fonts.scss" as fonts;

@use "sass:map" as map;
@use "../../styles/colors.scss";
@use "../../styles/common.scss";
@use "../../styles/fonts.scss";
@use "sass:math";
@import "~bootstrap/scss/variables";

.payment-invoice-detail-line{
  @extend .d-flex;
  @extend .flex-fill;
  @extend .flex-column;
  @extend .flex-sm-row;
  @extend .px-3;
  @extend .flex-wrap;
}
.payment-invoice-detail{
  min-height: 34px;
  @extend .d-flex;
  @extend .align-items-center;
}
.payment-invoice-detail-input{
  min-height: 38px;
  @extend .d-flex;
  align-items: stretch;
  @media (min-width: map-get($grid-breakpoints, "sm")) {
    align-items: center;
  }
}
.payment-invoice-detail-reason{
  flex: 0 0 math.div(1.5, 5);
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .justify-content-sm-start;
}
.payment-invoice-detail-comment{
  flex: 0 0 math.div(1.5, 5);
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .justify-content-sm-start;
}

.details-label-column {
  color: #6c757d;
  @media (min-width: map-get($grid-breakpoints, "sm")) {
    width: 60px;
  }
}


.checkout-invoice-detail {
  padding: 1em 0;
  position: relative;

  &:nth-child(odd) {
    background: $wa-page-background;
  }

  font-size: .875rem;
  line-height: 1.5;
}

.remove-invoice-button {
  position: absolute;
  z-index: 500;
  top: 0;
  right: 0;
}

.invoiceDetailCustomSelect select, .invoiceDetailCustomSelect button {
  color: $wa-theme-color !important;
  display: inline-block;
  font-family: $wa-m-plus-rounded-font-family;
  font-size: 14px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.invoice-dollar-input {
  height: 35px!important;
  border-bottom-left-radius: 0.2rem!important;
  border-top-left-radius: 0.2rem!important;
}

.invoice-amount-input {
  height: 35px!important;
  border-top-left-radius: 0px!important;
  border-bottom-left-radius: 0px!important;
}