//CNBS Colors
$primary-accent: #03838c;
$secondary-accent: white;

//Schindler Colors
//$primary-accent: #c80000;
//$secondary-accent: white;

//Universal Colors 
$header-background: #C2C2C2;
$register-background: rgba(114, 206, 155, 0.1);
$page-background: #F5F7FA;
$control-borders: #AEB4B7;
$control-text: #4A4A4A;
$control-label: #807F7F;

//Theme specific, these are largely the colors used in other scss files
$wa-theme-color: $primary-accent;
$wa-button-color: #336699;
$wa-input-theme-color:$primary-accent;
$wa-button-font-color: white;
$wa-heading-font-color: #336699;
$wa-grid-header-color:$primary-accent;
$wa-sub-header-color: $primary-accent;
$wa-nav-link-color: $secondary-accent;
$wa-nav-link-active-color: 2px solid $secondary-accent;

// Universal
$wa-control-border: 1px solid $control-borders;
$wa-control-text: $control-text;
$wa-control-label-color: $control-label;
$wa-green-color:#00b700;
$wa-red-color:#E80407;
$wa-back-button-color: #DEDEDE;
$wa-table-header-color: $header-background;
$wa-register-color: $register-background;
$wa-page-background: $page-background;





