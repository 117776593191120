@use '../../styles/colors.scss';
@use '../../styles/fonts.scss';
// :root {
//   --main-bg-color: #005288;
// }
.buttonColor {
    background-color: $wa-button-color;
}

.buttonsize {
    width: 157px !important;
}
body {
    background-color: $wa-page-background !important;
}

.btn-primary.loginButtonColor {
    background-color: $wa-button-color;
    font-weight: bold;
}

.heading {
    color: $wa-heading-font-color;
    font-family: $wa-m-plus-rounded-font-family, sans-serif;
    font-size: 32px;
    font-weight: bold;
}

.btn-secondary.registerButtonColor {
    background-color: $wa-register-color;
    border: $wa-control-border;
    font-weight: bold;
}

.register-button-text {
  //  color: #4A4A4A;
}

.trouble {
    color: $wa-heading-font-color;
    font-size: 1rem;
}

.center_div {
    margin: 0 auto;
    width: 80% /* value of your choice which suits your alignment */
}

.Absolute-Center {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
}

.Absolute-Center.is-Responsive {
    width: 50%;
    height: 50%;
    min-width: 50px;
    max-width: 50px;
    padding: 40px;
}

.control-border {
    border: $wa-control-border;
}

.button-class {
    font-family: $wa-m-plus-rounded-font-family; //"Arial Bold", Gadget, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: $wa-button-font-size !important; //to make fontsize bigger

}


.login-row {
    height: 86vh;

}

.logo-bottom-right {
    position: absolute;
    right: 22px;
    bottom: 82px;
}


.login-background {
    // background: url("/login_background.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    min-height: 100vh;

    .login-area {
        background: rgba(255, 255, 255, 0.78);
        border-radius: 10px;
    }
}


.banner-Div{
  margin: 50px;
  background-color:#F5F7FA;
  border: 1px solid #f8f9fa;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 110px 
}


.banner-main-div{
  padding-left: 0px;
  padding-right: 0px;
  background-color: white ;
  min-width: 400px;
}




