@use '../../styles/colors.scss';

.checkbox-personalized {
    * {
      box-sizing: border-box;
    }

    input[type=checkbox] {
      display: none;
    }

    label {
      margin: 0 auto;
      display: block;

      .check {
        margin:0 auto;
        width: 18px;
        height: 18px;
        border: 2px solid ($wa-input-theme-color);
        border-radius: 50%;
        text-align: center;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.2s ease-in;
        
        &.header-check{
          top:3px;
        }
      }
    }
  }

  .checkbox-personalized input[type=checkbox]:checked + label .check {
    background: ($wa-input-theme-color);
    transition: all 0.2s ease-in;
    border: 1px solid ($wa-input-theme-color);
  }

  .checkbox-personalized input[type=checkbox]:checked + label .check:after {
      content: '';
      display: block;
      width: 5px;
      height: 11px;
      border: solid #FFF;
      margin: 2px 6px;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
  }

  .checkbox-personalized input[type=checkbox]:indeterminate + label .check {
    background: #939ba2;
    transition: all 0.2s ease-in;
    border: 1px solid #939ba2;
  }
  
  .checkbox-personalized input[type=checkbox]:indeterminate + label .check:after {
    content: '';
    display: block;
    height: 11px;
    border: solid #FFF;
    margin: 2px 7px;
    border-width: 0 2px 0 0;
    transform: rotate(90deg);
  }
  
  .custom-select-body-class > tbody > tr > td:first-child, .custom-select-header-class > thead > tr > th:first-child {
    padding: 0 !important;
  }
  