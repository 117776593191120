@use '../../styles/colors.scss';
@use '../../styles/fonts.scss';

.invoicePage {
  
  .inv-calendar {
    background: white;
    color: $wa-heading-font-color;
    font-family: $wa-m-plus-rounded-font-family;
    font-weight: bold;
  }

  .invoicesHeadingInput, .invoicesHeadingInput .Select-control .Select-multi-value-wrapper .Select-value .Select-value-label {
    color: $control-text;;
    font-family: $wa-m-plus-rounded-font-family;
    font-weight: bold;
  }

  .soldto-select {
    button {    
      display: inline-flex;
      justify-content: space-between;
    }

    #soldToToggle::after {
      display: none;
    }

    .truncate {
      width: 300px;
    }
  }
}

.custom-table-header {
    th {
        padding: 0.75rem 0;
        background: $register-background;
        color: #03838c
    }
}

.buttonColor{
    background-color: $wa-button-color;
  }
  
  .invoicesHeading{
    color: $control-text;
    font-family: $wa-m-plus-rounded-font-family;
    font-size: 20px;
    font-weight: bold;
  }

  .detailScroll {
    overflow: auto;
    max-height: 350px;
  }

  .calendarPosition {
    position:absolute;
    text-indent:5px;
    margin-top:10px;
    margin-left:5px;
    color:$wa-grid-header-color;
    }
    
    .inp {
      // border-left: none;
      // border-top-left-radius: 0px;
      // border-bottom-left-radius: 0px;
      // margin-left: -2.1rem;
      text-indent:20px;
    }

    .billing_Number, .reference_Number {
      padding-right: 10px;
      padding-left: 10px;
      width: 250px;
    }

    .total {
      padding-right: 10px;
      width: 65px;
    }

    .total_Amount {
      padding-right: 10px;
      width: 200px;
    }

    .posting {
      padding-right: 10px;
      width: 50px;
    }

    .posting_date {
      padding-right: 10px;
      width: 100px;
    }

    .due {
      padding-right: 10px;
      width: 120px;
    }

    .due_date {
      padding-right: 10px;
      width: 100px;
    }

    .inv_Item {
      padding-right: 10px;
      width: 250px;
    }

    .pay{
      padding-right: 10px;
      width: 50px;
    }

    .pay_Amount {
      padding-right: 10px;
      width: 110px;
    }

    .reason{
      padding-right: 10px;
      width: 50px;
    }

    .reason_Selection {
      padding-right: 10px;
      width: 100px;
    }

    .reason_Selection_Height {
      height: 15px;
    }

    .reason_Description {
      width: 200px;
    }

    .invoice_Total {
      padding-right: 10px;
      width: 130px;
    }
    .invoice_Total_Amount {
      padding-right: 10px;
      width: 150px;
    }

    .payment_Label {
      padding-right: 10px;
      width: 50px;
    }
    .payment_Label_Amount {
      padding-right: 10px;
      width: 150px;
    }

    .overpaying {
      padding-right: 10px;
      width: 90px;
    }

    .overpaying_Amount {
      padding-right: 10px;
      width: 70px;
    }

    .overpaying_Amount_color {
      color: #00b700;
    }
    
    .circle_Exclamation_Color
    {
      color:orange;
    }

    .payment_Method_Label {
      padding-right: 10px;
      width: 130px;
    }
    .payment_Method_Selection {
      padding-right: 10px;
      width: 330px;
    }

    .payment_Button_Width {
      padding-right: 10px;
      width: 100px;
    }

    .imgPdf {
      content:url("../../images/ic_pdf.png");
      height: 20px;
      width: 20px;
    }

    .imgPdfDisabled {
      content:url("../../images/ic_pdf_disabled.png");
      height: 20px;
      width: 20px;
    }

    .imgSearch {
      content:url("../../images/ic_search.png");
    }


    .show {
      display: block !important;
    }
    .hidden {
      display: none !important;
      visibility: hidden !important;
    }

    .fa-times
    {
      margin-right: 10px;
      margin-top: 10px;
      font-size: 20px;
    }

    .row-margin
    {
      margin-top: -30px;
      margin-bottom: 10px;
    }

    .dots-z-index {
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 5;
  }

  .paymentPopup
  {
    width: 80%;
  }

  .itemPayment {
    color: $wa-green-color;
  }
  .customers-dialog-classname {
    display: block;
    display: flex;
    flex-direction: row;
    align-items: center; 
    width: 400px; 
    position: absolute;
    top: 33%;
    left: 32%;
    transform: translate(-50%, -50%) !important;
}
.customers-dialog-scroll {
  height: 300px;   
  overflow-y : auto;
}

.credit-positive, .credit-negative {
  font-family: $wa-m-plus-rounded-font-family;
  font-size: 20px;
  font-weight: bold;
}

.credit-positive{
  color: $wa-red-color;
}
.credit-negative{
  color: $wa-green-color;
}
/*
Tablet
*/
    @media screen and (max-width: 1024px) {
      .imgPdf {
        content:url("../../images/ic_pdf@2x.png");
        width:200px;
      }

      .imgPdfDisabled {
        content:url("../../images/ic_pdf_disabled@2x.png");
        width:200px;
      }

      .imgSearch {
        content:url("../../images/ic_search@2x.png");

      }
    }
/*
Cell phone
*/    
    @media screen and (max-width: 600px) {
      .imgPdf {
        content:url("../../images/ic_pdf.png");
      }

      .imgPdfDisabled {
        content:url("../../images/ic_pdf_disabled.png");
      }

      .imgSearch {
        content:url("../../images/ic_search.png");
      }
    }

    .soldToBtnColor {
      background-color: #FFFFFF;
    }
    
    .checkMargin {
      margin-right: 10px;
    }

    .multiSelectWidth {
      width: 200px; 
    }


    .caret-right {
      float: right;
    }

    .multiSelectMenuHeight {
      height: 200px; 
      overflow-y: auto;
    }

    #soldToToggle::after { 
      color: #aca9a9;
      position: absolute;
      right: 10px;
      top: 15px; 
  }

  .multiSelectItemColor {
    background-color: #DFECF5;
  }
    
input[type="checkbox"] {
    height: 17px;
    width: 17px;
    vertical-align: middle;
    margin: 0 0.4em 0.4em 0;
    border: 2px solid ($wa-input-theme-color);
  
    -webkit-appearance: none;
    -webkit-transition: box-shadow 200ms;
  
}

input[type="radio"] {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    margin: 3px;
    accent-color: ($wa-input-theme-color);
    /*border-radius: 50%;*/
}

/* border radius for checkbox */
input[type="checkbox"] {
    -webkit-border-radius:2px;
    border-radius:2px;
}

/* hover state */
input[type="checkbox"]:not(:disabled):hover {
    border-color:($wa-input-theme-color);
    
}

/* active state */
input[type="checkbox"]:active:not(:disabled) {
    border-color:($wa-input-theme-color);
}

/* focus state */
input[type="checkbox"]:focus {
    outline:none;
  
}

/* input checked border color */
input[type="checkbox"]:checked {
    border-color:($wa-input-theme-color);
    background-color: ($wa-input-theme-color);
}

/* checkbox checked */
input[type="checkbox"]:checked:before {
content: '';
display: block;
width: 4px;
height: 8px;
border: solid #fff;
border-width: 0 2px 2px 0;
-webkit-transform: rotate(45deg);
transform: rotate(45deg);
margin-left: 4px;
margin-top: 1px;
}

/* disabled input */
input:disabled {
opacity: .6;
box-shadow: none;
background: rgba(0, 0, 0, 0.1);
box-shadow:none;
}

/* style label for disabled input */
input:disabled + label {
opacity: .6;
cursor:default;
-webkit-user-select: none;
}

.darkerGrey{
  color: #000000;
}


.deletePadding{
  padding-top: 5px;
}

.soldToFloat{
  text-align: left;
  padding-left: 10px;
  font-size: 18px;
}

.soldToLableFloat{
  text-align: left;
  padding-left: 5px;
}

.padding-0{
  padding-right:0;
  padding-left:0;
}

.padding-left-10{
  padding-left:10px;
}
.reason-description-top{
  margin-top:10;
  padding-left:0;
}

.detail-link {
  color: $wa-heading-font-color;
  text-decoration: underline;
  font-family: $body-font-family;
  font-size: 14px;
}
    


.table-header-sort-icon{
  width:10px;
  height:10px;
  margin-left: 6px;
}
      
.column-header{
  cursor: default;
}

.column-header.sortable{
  cursor: pointer;
}

.status-good{
  //color:green !important;
}
.status-bad{
  color:red !important;
}
    

.invoice-total-label{
  letter-spacing: 0.4px;
  color: #42424A;
  padding: 0 14px;
}

.invoice-total-value{
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.705882px;
  color: $control-text;
}

.invoice-currency-warning{
  color: $wa-red-color;
}
.selection-cell-header {
    pointer-events: none !important;
}
.invoice-checkbox{
  width: 20px;
}
.rounded-lg {
  border-radius: 2.3rem !important;
}
.card-invoice{
  border: 2px solid #1a75bb !important;
  margin: 2px;
  padding: 0px !important;
  width:100%;
  margin-top: 15px;
}
.card-body-invoice  {
  padding: 0px !important;
  width:400px;
  margin-top: 15px;
}


.card-header-invoice {
  //margin-top: 10px;
  height: 60px;
  padding: 5px !important;
  background-color: #e0e0e0;   
  border-top-left-radius: 2.25rem !important;
  border-top-right-radius: 2.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

@media (min-width: 1px) and (max-width: 991px) {
  .detail-link {
    color: $wa-heading-font-color;
    text-decoration: underline;
    font-family: $body-font-family;
    font-size: 14px;
  }
  .status-bad{
    color:red !important;
    font-weight: bold !important;
    font-size: 18px !important;
  }
  .status-good
  {
    font-size: 18px !important;

  }
  .main-table-cell-invoice  span{
    margin-top: 0px;
    color:green !important ;
    font-size: 18px !important;
  }
  .main-table-cell{
    font-size: 16px !important;   

  }
  .row-height{
    height: 20px;
  }
}
 

a.dropdown-item {
  font-size: 13px;
}
